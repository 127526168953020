import { MP4Box } from "./mp4box.all"

class Fmp4 {
  static CHUNKS_SIZE = 2000000
  static FIRST_CHUNK_SIZE = 3000

  constructor(video, srcUrl, handleError) {
    this.debug = false  // for debug => true: show debug message / false: hide debug message
    this.video = video
    this.srcUrl = srcUrl
    this.handleError = handleError
    this.mediaSource = new MediaSource
    this.sourceBuffer = null
    this.mp4data = null
    this.videoFileSize = 0
    this.segments = []
    this.segIndex = 0
    this.mp4ParseReady = 0
    this.appendBufferCnt = 0
    this.chunkCount = 0
    this.filePos = 0

    this.onSourceOpen = this.sourceOpen.bind(this)
    
    this.init()
  }

  init() {
    if ('MediaSource' in window /*&& MediaSource.isTypeSupported(mimeCodec)*/) {
      this.video.src = URL.createObjectURL(this.mediaSource)
      this.mediaSource.addEventListener('sourceopen', this.onSourceOpen)
    }
  }

  sourceOpen() {
    //get mimeCodec string from record file
    this.createMP4Box()
    this.fetchFileSize(this.srcUrl)
  }

  checkVideoBufferedQueue() {
    if (!this.sourceBuffer) return // 不加這個判斷，結束了timer還會一直跑
    let buffered_len = this.video.buffered.end(0) - this.video.currentTime
    this.debug && console.log("video buffered time: ", buffered_len)
    if (buffered_len > 60) {
      setTimeout(() => {
        this.checkVideoBufferedQueue()
      }, 3000)
    } else {
      this.addVideoSourceBuffer()
    }
  }

  createMediaSourceBuffer(mimeCodec) {
    this.mp4ParseReady = 1
    if (MediaSource.isTypeSupported(mimeCodec)) {
      try {
        this.sourceBuffer = this.mediaSource.addSourceBuffer(mimeCodec)
      }
      catch (error) {
        if (error.name == 'NotSupportedError') {
          console.error('NotSupportedError --> mimeCodec: ', mimeCodec)
          this.mediaSource.endOfStream()
          this.handleError(mimeCodec)
          return
        }
      }

      this.sourceBuffer.addEventListener('updateend', () => {
        this.debug && console.log("sourceBuffer updateend: updateend status: ", this.mediaSource.readyState)
        this.debug && console.log("sourceBuffer updateend: append Buffer Count ", this.appendBufferCnt)

        if (this.video.buffered > 0) 
          console.log("sourceBuffer updateend: video buffered end: ", this.video.buffered.end(0))

        if (this.appendBufferCnt == this.chunkCount) { //receive last chunk
          if (!this.sourceBuffer.updating) {
            this.mediaSource.endOfStream()
          }
        } else {
          if (!this.sourceBuffer.updating) {
            if (this.video.buffered.length > 0) {
              let buffered_len = this.video.buffered.end(0) - this.video.currentTime
              this.debug && console.log("video buffered time: ", buffered_len)
              if (buffered_len > 60) { // buffer max 1 min
                setTimeout(() => { 
                  this.checkVideoBufferedQueue() 
                }, 5000)
              } else {
                this.debug && console.log('sourceBuffer updateend: call addVideoSourceBuffer (1)')
                this.addVideoSourceBuffer()
              }
            } else {
              this.debug && console.log('sourceBuffer updateend: call addVideoSourceBuffer (2)')
              this.addVideoSourceBuffer()
            }
          }
          if (this.appendBufferCnt == 1) {
            this.video.play()
            this.debug && console.log('video playing')
          }
        }
      })
      this.sourceBuffer.appendBuffer(this.mp4data.stream.buffer)
    } else {
      console.error('Unsupported MIME type or codec: ', mimeCodec)
      this.mediaSource.endOfStream()
      this.handleError(mimeCodec)
    }
  }

  setVideoSize(size) {
    this.videoFileSize = size
    this.chunkCount = Math.ceil((this.videoFileSize - Fmp4.FIRST_CHUNK_SIZE) / Fmp4.CHUNKS_SIZE) + 1 //first chunk: get moov first
    this.debug && console.log('chunkCount: ', this.chunkCount)
    if (this.videoFileSize) {
      this.fetchVideoDataSegment(this.srcUrl, this.segIndex++, this.segments, 'start') //start to get first segment
    }
  }

  addMP4BoxBuffer() {
    let segment = this.segments.shift()
    if (segment) {
      this.appendBufferCnt++
      segment.fileStart = this.filePos
      this.mp4data.appendBuffer(segment)
      this.filePos += segment.byteLength
    }
  }

  addVideoSourceBuffer() {
    if (this.debug && !this.segments.length) console.error("segments empty")
    this.debug && console.log('mediaSource status: ', this.mediaSource.readyState)

    if (this.sourceBuffer) this.debug && console.log('append Source buffer')
    else return console.error("sourceBuffer null")

    if (this.sourceBuffer.updating) return console.error("buffer updating")

    if (this.segments.length) {
      let segment = this.segments.shift()
      if (segment) {
        this.debug && console.log('addBuffer: appendBuffer (fetch callback) seg size ', segment.byteLength)
        this.appendBufferCnt++
        this.sourceBuffer.appendBuffer(segment)
      }
    } else {
      if (this.segIndex < this.chunkCount) {
        this.fetchVideoDataSegment(this.srcUrl, this.segIndex++, this.segments) // get next until onReady
      }
    }
  }

  createMP4Box() {
    this.mp4data = MP4Box.createFile()
    this.mp4data.onReady = (info) => {
      let mimestr = 'video/mp4; codecs="'
      for (let i = 0; i < info.tracks.length; i++) {
        if (i !== 0) mimestr += ','
        mimestr += info.tracks[i].codec
      }
      mimestr += '"'
      this.createMediaSourceBuffer(mimestr)
    }
    this.mp4data.onError = (error) => {
      console.error("mp4data error:", error)
    }
  }

  async fetchFileSize(url) {
    try {
      const response = await fetch(url, {method: 'HEAD'})
      if (response.ok) {
        let videoFileSize = response.headers.get('Content-Length')
        this.setVideoSize(videoFileSize)
      }
    } catch (error) {
      console.error("fetch error:", error)
    }
  }

  async fetchVideoDataSegment(url, index, segments, mode = '') {
    this.debug && console.log("fetchVideoDataSegment: segment = ", index)
    try {
      let start, end
      if (index == 0) {
        start = 0
        end = Fmp4.FIRST_CHUNK_SIZE - 1
      } else {
        start = Fmp4.CHUNKS_SIZE * (index-1) + Fmp4.FIRST_CHUNK_SIZE
        end = Math.min(start + Fmp4.CHUNKS_SIZE -1, this.videoFileSize -1)
      }

      this.debug && console.log(`fetchVideoDataSegment: fetch data from ${start} - ${end}`)

      const response = await fetch(url, {
        headers: {
          Range: `bytes=${start}-${end}`,
        },
      })

      if (response.ok) {
        const arrayBuffer = await response.arrayBuffer()
        segments.push(arrayBuffer)
        if (mode === 'start') {
          this.addMP4BoxBuffer()
        } else {
          this.addVideoSourceBuffer()
        }
      }
    } catch (error) {
      console.error("fetchVideoDataSegment: fetch error:", error)
    }
  }

  close() {
    if (this.mediaSource) 
      this.mediaSource.removeEventListener('sourceopen', this.onSourceOpen)
    this.mediaSource = null
    this.sourceBuffer = null
    this.mp4data = null
    this.segments = []
    this.segIndex = 0
    this.mp4ParseReady = 0
    this.appendBufferCnt = 0
    this.chunkCount = 0
    this.filePos = 0
  }
}

export default Fmp4